import './header.css';
const Header = () => {
  return (
    <header>
        <h1>RepoFind</h1>

    </header>
  );
};

export { Header };
